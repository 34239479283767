import { UserRole, UserType } from '@/app/(pages)/account/types';
import * as request from '@/packages/api/helpers';

export type GetUserOptions = {
  userId: string;
};

export type GenerateInvitationLinkOptions = {
  userName: string;
};

export type GenerateInvitationLinkResponse = {
  link: string;
};

export type SetPasswordFromInvitationLinkOptions = {
  userName: string;
  newPassword: string;
  token: string;
};

export type SetPasswordFromForgotPasswordLinkOptions = {
  userName: string;
  newPassword: string;
  token: string;
};

export type SetPasswordFromForgotPasswordLinkResponse = {
  success: boolean;
  errors: [
    {
      code: string;
      description: string;
    },
  ];
};

export type GetUserResponse = {} & UserType;
export type GetAllUsersResponse = Array<UserType>;

export type ForgotPasswordResponse = {
  token: string;
  errors: [
    {
      code: string;
      description: string;
    },
  ];
};

export type ForgotPasswordOptions = {
  userName: string;
};

export type PostUserOptions = {
  userName: string;
  fullName: string;
  role: UserRole;
  approvalLevels: string[];
};

export type UpdateUserOptions = {
  id: string;
  userName: string;
  fullName: string;
  role: UserRole;
  approvalLevels: string[];
};

export type DeleteUserOptions = {
  userId: string;
};

export const user = {
  /**
   * Get user
   */
  get: async (payload: GetUserOptions) => await request.get<GetUserResponse>(`/api/user/${payload.userId}`, undefined, { next: { tags: ['user'] } }),

  /**
   * Get all users
   */
  getAll: async () => await request.get<GetAllUsersResponse>(`/api/user`),

  /**
   * Post user
   */
  post: async (payload: PostUserOptions) => await request.post<any>(`/api/user/`, payload, { invalidateTags: ['user'] }),

  /**
   * Update user
   */
  update: async (payload: UpdateUserOptions) => await request.put<any>(`/api/user/${payload.id}`, payload, { invalidateTags: ['user'] }),

  /**
   * Delete user
   */
  delete: async (payload: DeleteUserOptions) => await request.del<any>(`/api/user/${payload.userId}`, undefined, { invalidateTags: ['user'] }),

  /**
   * Hard delete user
   */
  hardDelete: async (payload: DeleteUserOptions) => await request.del<any>(`/api/user/${payload.userId}/hard-delete`, undefined, { invalidateTags: ['user'] }),

  /**
   * Forgot password
   */
  forgotPassword: async (payload: ForgotPasswordOptions) => await request.post<ForgotPasswordResponse>(`/api/user/forgot-password`, payload),

  /**
   * Set password from invitation link
   */
  setPasswordFromForgotPasswordLink: async (payload: SetPasswordFromForgotPasswordLinkOptions) =>
    await request.post<SetPasswordFromForgotPasswordLinkResponse>(`/api/user/set-password-from-forgot-password-link`, payload),

  /**
   * Generate invitation link
   */
  generateInvitationLink: async (payload: GenerateInvitationLinkOptions) => await request.post<GenerateInvitationLinkResponse>(`/api/user/generate-invitation-link`, payload),

  /**
   * Set password from invitation link
   */
  setPasswordFromInvitationLink: async (payload: SetPasswordFromInvitationLinkOptions) => await request.post<any>(`/api/user/set-password-from-invitation-link`, payload),
};
