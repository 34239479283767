import * as request from '@/packages/api/helpers';

export type CampaignType = {
  campaignId: string;
  name: string;
  campaignGroup: {
    id: string;
    name: string;
  };
  releaseId: string;
  audioTemplateId: number;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  status: CampaignStatusType;
  totalAds: string;
  totalComments: number;
  spots: Array<CampaignSpotType>;
};

export type CampaignStatusType = 'NotSet' | 'InProgress' | 'ReadyForRelease' | 'ReleaseFailed' | 'ReleaseInProgress' | 'Released';
export const enum CampaignSpotApprovalStatusType {
  NotSet = 'NotSet',
  Pending = 'Pending',
  Approved = 'Approved',
  Discarded = 'Discarded',
  RequestChanges = 'RequestChanges',
  ApprovedWithChanges = 'ApprovedWithChanges',
}

export type CampaignSpotTrackFiltersType = {
  adelay?: string;
  volume?: string;
  atempo?: string;
  asetrate?: string;
  aresample?: string;
};

export type AudioInfo = {
  format: 'mp3' | 'wav';
  bitsPerSample: number;
  duration: number;
  sampleRate: number;
  numberOfChannels: number;
  bitrate: number;
};

export type CampaignSpotTrackType = {
  audioTemplateTrackConfigId: string;
  trackHistoryId?: string;
  trackStatus?: string;
  trackId?: string;
  assetId?: string;
  text?: string;
  createdAt?: string;
  filters?: string;
  waveformPath?: string;
  modifiedAt?: string;
  spotReviewStatus?: string;
  totalComments?: number;
  shouldRemix?: boolean;
  hasAudioTrackFileBeenOverridden: boolean;
  audioInfo: AudioInfo;
};

export type CampaignSpotApprovalLevelType = {
  approvalLevel: number;
  approvalStatus: CampaignSpotApprovalStatusType;
  eventDate?: string;
  approver?: {
    userName: string;
    fullName: string;
  };
  reviewLevelId: number;
};

export type CampaignSpotType = {
  name: string;
  spotId: string;
  campaignId: string;
  spotCategoryId: string;
  division: string;
  metadata: string;
  createdAt: string;
  modifiedAt: string;
  // modifiedBy: string;
  mergedAudioAssetId?: string;
  mergedAssetAudioInfo: AudioInfo;
  tracks: CampaignSpotTrackType[];
  approvalLevels: Array<CampaignSpotApprovalLevelType>;
  totalComments: number;
  latestMixHistory?: {
    id: string;
    log: string;
  };
  previousReviewGroupId?: number;
  currentReviewGroupId: number;
  nextReviewGroupId?: number;
  previousReviewLevelId?: number;
  currentReviewLevelId: number;
  nextReviewLevelId?: number;
};

export type PostCampaignPayload = {
  campaignGroupId: string;
  name: string;
  audioTemplateConfigId: string;
  releaseId: string;
};

export type PostCampaignResponse = {
  campaignId: string;
};

export type PutCampaignOptions = {
  campaignId?: string;
  campaignGroupId: string;
  name: string;
  releaseId: string;
  id: string;
};

export type GetCampaignOptions = {
  id: string;
};

export type PostCampaignNewSpotResponse = {
  spotId: string;
};

export type PostCampaignNewSpotOptions = {
  campaignId: string;
  spots: Array<{
    category: string;
    division: string;
  }>;
};

export type GetCampaignResponse = {} & CampaignType;

export type GetAllCampaignsItemsType = {
  campaignGroup: string;
  campaignGroupId: string;
  createdAt: string;
  totalAds: number;
  status: CampaignStatusType;
  campaigns: Array<CampaignType>;
};

export type GetAllCampaignsResponse = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  totalPages: number;
  items: Array<GetAllCampaignsItemsType>;
};

export type GetAllCampaignsOptions = {
  currentPage?: string;
  itemsPerPage?: string;
};

export type DeleteCampaignOptions = {
  id: string;
};

export type DeleteCampaignResponse = {
  campaignId: string;
};

export const campaign = {
  /**
   * Post a campaign
   */
  post: async (payload: PostCampaignPayload) => request.post<PostCampaignResponse>(`/api/campaign/`, payload, { invalidateTags: ['campaign'] }),

  /**
   * Create new spot(s) for the campaign
   */
  newSpot: async (payload: PostCampaignNewSpotOptions) => await request.post<PostCampaignNewSpotResponse>(`/api/campaign/${payload.campaignId}/add-spots/`, payload, { invalidateTags: [`campaign`] }),

  /**
   * Get a campaign
   */
  get: async (payload: GetCampaignOptions) => await request.get<GetCampaignResponse>(`/api/campaign/${payload.id}`, undefined, { next: { tags: ['campaign'] } }),

  /**
   * Delete a campaign
   */
  delete: async (payload: DeleteCampaignOptions) => await request.del<DeleteCampaignResponse>(`/api/campaign/${payload.id}`, undefined, { invalidateTags: [`campaign`] }),

  /**
   * Update a campaign
   */
  put: async (payload: PutCampaignOptions) => await request.put<GetCampaignResponse>(`/api/campaign/${payload.campaignId}`, payload, { invalidateTags: [`campaign`] }),

  /**
   * Get all campaigns
   */
  getAll: async (payload?: GetAllCampaignsOptions) => await request.get<GetAllCampaignsResponse>(`/api/campaign/list`, payload, { next: { tags: ['campaign'] } }),
};
