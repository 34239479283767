import * as request from '@/packages/api/helpers';
import { CampaignSpotTrackType } from './campaign';

export type GetTrackHistoryOptions = {
  trackId: string;
};

export type GetSpotTrackHistoryResponse = Array<CampaignSpotTrackType>;

export type GenerateVOAudioPayload = {
  trackId: string;
  text: string;
};

export type GenerateVOAudioResponse = {
  trackId: string;
  trackHistoryId: string;
  url: string;
};

export type PostTrackPayload = {
  spotId: string;
  audioTemplateTrackId: string;
  assetId?: string;
  text?: string;
};

export type PostTrackResponse = {
  trackId: string;
};

export type UpdateTrackPayload = {
  trackId: string;
  filters?: string;
  text?: string;
  assetId?: string;
};

export type OverrideTrackTrackPayload = {
  trackId: string;
  assetId?: string;
};

export type UpdateTrackResponse = {
  trackId: string;
};

export type PostTrackWaveformPathPayload = {
  trackId: string;
  waveformPath: string;
};

export const track = {
  /**
   * Generate a new TTS VO audio
   */
  generateVOAudio: async (payload: GenerateVOAudioPayload) =>
    await request.post<GenerateVOAudioResponse>(`/api/track/${payload.trackId}/generate-vo-audio`, payload, { invalidateTags: ['track-history'] }),

  /**
   * Get a spot's track vo history
   */
  getHistory: async (payload: GetTrackHistoryOptions) => await request.get<GetSpotTrackHistoryResponse>(`/api/track/${payload.trackId}/history`, undefined, { next: { tags: ['track-history'] } }),

  /**
   * Create a spot track
   */
  create: async (payload: PostTrackPayload) => await request.post<PostTrackResponse>(`/api/track/`, payload, { invalidateTags: ['spot'] }),

  /**
   * Update a spot track
   */
  update: async (payload: UpdateTrackPayload) => await request.put<UpdateTrackResponse>(`/api/track/${payload.trackId}`, payload, { invalidateTags: ['spot'] }),

  /**
   * Override a spot track
   */
  overrideTrack: async (payload: OverrideTrackTrackPayload) => await request.post(`/api/track/override-asset/`, payload, { invalidateTags: ['spot'] }),

  /**
   * Update a spot track waveform path
   */
  waveformPath: async (payload: PostTrackWaveformPathPayload) => await request.post(`/api/track/update-waveform-path`, payload, { invalidateTags: ['spot'] }),
};
